/* Project specific Javascript goes here. */
var textElement = document.getElementById("text");

function applyHighlights(highlights,textElement) {
    const fullText = textElement.innerText; // Get the complete text from the element
    const fragments = [];

    // Sort highlights by start position to ensure sequential processing
    highlights.sort((a, b) => a.start - b.start);

    let lastIndex = 0;
    let lastHighlightedEnd = 0; // Track the end of the last highlighted segment

    highlights.forEach(highlight => {
        const { start, end, color } = highlight;

        // If the current highlight starts after the last highlight ends, add the text before this highlight
        if (start > lastHighlightedEnd) {
            fragments.push(fullText.slice(lastHighlightedEnd, start));
        }

        // If the highlight overlaps with the previous one, adjust the start and end positions
        const effectiveStart = Math.max(start, lastHighlightedEnd);
        const effectiveEnd = Math.min(end, fullText.length);

        // Add the highlighted text wrapped in a span
        if (effectiveStart < effectiveEnd) {
            const highlightedText = fullText.slice(effectiveStart, effectiveEnd);
            const span = `<span style="background-color: ${color};" class="highlighted-text">${highlightedText}</span>`;
            fragments.push(span);
        }

        // Update the last index to track the end of the current highlight
        lastHighlightedEnd = Math.max(lastHighlightedEnd, effectiveEnd);
    });

    // Add any remaining text after the last highlight
    if (lastHighlightedEnd < fullText.length) {
        fragments.push(fullText.slice(lastHighlightedEnd));
    }

    // Update the innerHTML of the element with the fragments
    textElement.innerHTML = fragments.join("");
}

function removeHighlight(highlight) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const pageContainer = document.getElementById("page-container");
    const pageId = pageContainer.getAttribute("data-page-id");

    const formData = new FormData();
    formData.append('page_id', pageId);
    formData.append('start', highlight.start);


    fetch('/books/delete_highlight/', {
        method: 'POST',
        body: formData,
             headers: {
        'X-CSRFToken': csrfToken, // Add CSRF token to the request
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  // Event listener for right-click on a highlight
  textElement.addEventListener("contextmenu", function (event) {
    const target = event.target;

    // Only show the menu for highlighted spans
    if (target.classList.contains("highlighted-text")) {
      const highlight = highlights.find(h => h.text === target.textContent);
      if (highlight) {
        createContextMenu(event, highlight);
      }
    }
  });



  function highlightSelection(color) {
    const textElement = document.getElementById("text");
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    // Check if there is any selection made
    // libray
    if (!selection.rangeCount || selectedText === "") return;

    const range = selection.getRangeAt(0);
    const fullText = textElement.innerText.trim(); // Using innerText for accurate text

    // Find the start index of the selected text
    const start = fullText.indexOf(selectedText);
    const end = start + selectedText.length;

    // Check if the selection is within the "text" div
    if (!textElement.contains(range.startContainer) || !textElement.contains(range.endContainer)) {
      return;
    }

    const span = document.createElement("span");
    span.style.backgroundColor = color;
    span.className = "highlighted-text";

    // Wrap the selected text in a span
    span.appendChild(range.extractContents());
    range.insertNode(span);

    // Clear the selection
    selection.removeAllRanges();

    // Save highlight 
    SaveHighlight(color, selectedText, start, end);
  }

  function SaveHighlight(color, selectedText, start, end) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;


    const pageContainer = document.getElementById("page-container");
    const pageId = pageContainer.getAttribute("data-page-id");

    const formData = new FormData();
    formData.append('page_id', pageId);
    formData.append('start', start);
    formData.append('end', end);
    formData.append('color', color);
    formData.append('text', selectedText);

    fetch('/books/highlight/', {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRFToken': csrfToken, // Add CSRF token to the request
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        document.body.dispatchEvent(new Event("open_highlights"));
        return response.text();
        
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }